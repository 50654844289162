import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Location from '../components/Page/Location'
import BannerImage from '../components/Page/BannerImage'
import LocalAttractions from '../components/Page/LocalAttractions'

const locationPage = ({data}) => {
	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="bg-boswell-handwriting pt-12 pb-16">
				<div className={`flex flex-row items-start flex-wrap `}>
					<div className="md:w-1/2">
						<Location />
					</div>
					<div className="md:w-1/2">
						<LocalAttractions />
					</div>
				</div>
			</section>
		</Layout>
	)
}

export const query = graphql`{
  file(relativePath: {eq: "banner/Boswell-Book-Festival-Location.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default locationPage
